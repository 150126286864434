<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <h4 class="mr-sm-4 header-tablepage">LOG DETAIL : {{ rqp_no }}</h4>
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(action_time)="data">
            <div>{{ data.item.action_time | moment($formatDateNew) }}</div>
            <div class="time-color">
              {{ data.item.action_time | moment("HH:mm:ss") }}
            </div>
          </template>
          <template v-slot:cell(status)="{ item }">
            <div
              :class="item.status === 'reject' ? 'text-error' : 'text-success'"
            >
              {{ item.status == "reject" ? "Reject" : "Approve" }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import OtherLoading from "@/components/loading/OtherLoading";

export default {
  components: {
    OtherLoading,
  },
  data() {
    return {
      isLoading: true,
      filter: {
        rqp_id: this.$route.params.log_id,
        search: "",
      },
      rqp_no: "",
      isBusy: true,
      fields: [
        {
          label: "วันที่",
          key: "action_time",
        },
        {
          label: "ผู้ดำเนินการ",
          key: "action_by_name",
        },
        {
          label: "รายการ",
          key: "rqp_product_name",
        },
        {
          label: "Action",
          key: "status",
        },
      ],
    };
  },
  async created() {
    await this.getList();
  },
  methods: {
    async getList() {
      this.isBusy = true;
      let payload = { ...this.filter };

      const res = await this.axios.post(`/rqp/logs`, payload);

      if (res.data.result == 1) {
        this.items = res.data.detail.logs;
        this.rqp_no = res.data.detail.rqp_no || "";
        this.rows = res.data.detail.total_count;
      }
      this.isLoading = false;
      this.isBusy = false;
    },
  },
};
</script>
